export const Colors = {
  primary: "#ff0000",
  dark: "#424141",
  text: "#343434",
  textLight: "#505050",
};

//colors
export const colorsLight = {
  primary: "#e63c19",
  secondary: "#3d2c29",
  text: "#3d2c29",
  background: "#fafaf9",
  highlight: "#f0dfdb",
  muted: "#f3eeed",
};
//colors
export const colorsDark = {
  primary: "#e63c19",
  secondary: "#3d2c29",
  text: "#f3eeed",
  background: "#3d2c29",
  highlight: "#f0dfdb",
  muted: "#f3eeed",
};

// theme.js
export const lightTheme = {
  body: colorsLight.background,
  text: "#363537",
};

export const darkTheme = {
  body: colorsDark.background,
  text: "#FAFAFA",
};
