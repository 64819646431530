import React from "react";

//components
// import NavbarComponent from "../components/Navbar.Component";
import AuthAwareNavbar from "../components/AuthAwareNavbar.component";

const Layout = (props) => {
  return (
    <div>
      <AuthAwareNavbar user={props.user} />
      <div className="container-fluid pt-2 pt-md-4 px-2 px-md-4">
        {props.children}
      </div>
    </div>
  );
};

export default Layout;
