import React, { useContext, Component } from "react";
import Layout from "../app.layout";

//vimeo
import Vimeo from "@u-wave/react-vimeo";
import { Col, Row } from "react-bootstrap";

import Resources from "../../components/Resources.Component";
import { Switch, Route } from "react-router-dom";

//screens
import SelectSubject from "../select_subject/SelectSubject";
import SelectTeacher from "../select_teacher/SelectTeacher";

const videos = [
  { id: 0, title: 'Welcome', vimeoId: '421982514'},
  { id: 1, title: '1', vimeoId: '421963212'},
  { id: 2, title: '2', vimeoId: '421963436'},
  { id: 3, title: '3', vimeoId: '421964216'},
  { id: 4, title: '4', vimeoId: '421964521'},
  { id: 5, title: '5', vimeoId: '421964618'},
  { id: 6, title: '6', vimeoId: '421964952'},
  { id: 7, title: '7', vimeoId: '421965121'},
  { id: 8, title: '8', vimeoId: '421965308'},
  { id: 9, title: '9', vimeoId: '421965465'},
  { id: 10, title: '10', vimeoId: '421965674'},
  { id: 11, title: '11', vimeoId: '421968021'},
  { id: 12, title: '12', vimeoId: '421968154'},
  { id: 13, title: '13', vimeoId: '421968533'},
  { id: 14, title: '14', vimeoId: '421968747'},
  { id: 15, title: '15', vimeoId: '421968898'},
  { id: 16, title: '16', vimeoId: '421969161'},
  { id: 17, title: '17', vimeoId: '421969576'},
  { id: 18, title: '18', vimeoId: '421969679'},
  { id: 19, title: '19', vimeoId: '421969883'},
  { id: 20, title: '20', vimeoId: '421970135'},
  { id: 21, title: '21', vimeoId: '421970457'},
  { id: 22, title: '22', vimeoId: '421970798'},
  { id: 23, title: '23', vimeoId: '421972548'},
  { id: 24, title: '24', vimeoId: '421972789'},
  { id: 25, title: '25', vimeoId: '421973107'},
  { id: 26, title: '26', vimeoId: '421973372'},
  { id: 27, title: '27', vimeoId: '421973508'},
  { id: 28, title: '28', vimeoId: '421973850'},
  { id: 29, title: '29', vimeoId: '421974214'},
  { id: 30, title: '30', vimeoId: '421974679'},
  { id: 31, title: '31', vimeoId: '421975320'},
  { id: 32, title: '32', vimeoId: '421975915'},
  { id: 33, title: '33', vimeoId: '421976598'},
  { id: 34, title: '34', vimeoId: '421977281'},
  { id: 35, title: '35', vimeoId: '421977084'},
  { id: 36, title: '36', vimeoId: '421977628'},
  { id: 37, title: '37', vimeoId: '421977806'},
  { id: 38, title: '38', vimeoId: '421978145'},
  { id: 39, title: '39', vimeoId: '421978366'},
  { id: 40, title: '40', vimeoId: '421978605'},
  { id: 41, title: '41', vimeoId: '421978910'},
  { id: 42, title: '42', vimeoId: '421979179'},
  { id: 43, title: '43', vimeoId: '421979552'},
  { id: 44, title: '44', vimeoId: '421979851'},
  { id: 45, title: '45', vimeoId: '421980247'},
  { id: 46, title: '46', vimeoId: '421980623'},
  { id: 47, title: '47', vimeoId: '421981060'},
  { id: 48, title: '48', vimeoId: '421981368'},
  { id: 49, title: '49', vimeoId: '421981736'},
  { id: 50, title: '50', vimeoId: '421982112'},
];

class Video extends Component{
  constructor(props){
    super(props);
  }

  onVideoClick(index){
    this.props.onClick(index);
  }

  render(){
    return (
      <div style={{
        width: 100,
        height: 100,
        padding: 5,
        display: 'inline-block',
        border: '1px solid black',
        margin: 3,
        textAlign: "center",
        lineHeight: '90px'
      }}
        onClick={() => this.onVideoClick(this.props.id)}
      >
        {this.props.name}
      </div>
    );
  }
}

const Discussion = (props) => {
  return (
    <Layout user={props.user}>
      <Switch>
        <Route path="/" component={DiscussionBody} />
        <Route path="/select-subject" component={SelectSubject} />
        <Route path="/select-teacher" component={SelectTeacher} />
      </Switch>
    </Layout>
  );
};

class DiscussionBody extends Component {
  constructor(props){
    super(props);
    this.state = {
      currentVideoIndex: 0
    };
  }

  getVimeoId(){
    var index = this.state.currentVideoIndex;
    var filtered = videos.filter(i => i.id == index);

    if( filtered && filtered.length > 0 ){
      return filtered[0].vimeoId; 
    }else{
      return videos[0].vimeoId;
    }    
  }

  onVideoClick(videoIndex){
    this.setState({
      currentVideoIndex: videoIndex
    })
  }

  render() {
    return (
      <div>
        <Row>
          <Col lg={8}>
            <Vimeo color="007bf" video={this.getVimeoId()} responsive />
          </Col>
          <Col className="pt-3 pt-lg-0 pl-lg-0" lg={4}>
            <div className="question-answers-view p-3">
              <div>
                <h6>Videos</h6>
              </div>
              <div className="question-list-view" id="style-1">
                <div className="overflow-view px-2 py-1">
                  { videos && Array.isArray(videos) && videos.length > 0 &&
                      videos.map((video, index) => (
                        <Video
                          key={index}
                          id={video.id}
                          name={ video.title }
                          vimeoId={video.vimeoId}
                          onClick={this.onVideoClick.bind(this)}
                        />
                      ))
                  }
                </div>
              </div>
            </div>
            {/* <VideoSection videos={videos} onClick={ () => this.onVideoClick } /> */}
          </Col>
        </Row>
        <Row style={{ marginBottom: 100 }}>
          <Col lg={8}>
            {/* <div className="py-3 pl-1">
        <h4 style={{ fontWeight: "bold" }}>Sample Video Title</h4>
        <p style={{ fontSize: 14 }}>
          Et in aliqua ut est aliquip laborum fugiat. Sit pariatur amet sint
          qui ut non ipsum sit ex veniam qui ad. Et in aliqua ut est aliquip
          laborum fugiat. Sit pariatur amet sint qui ut non ipsum sit ex
          veniam qui ad.{" "}
        </p>
        <a style={{ fontSize: 14 }} href="/#">
          Show more <Icon.ChevronDown size={15} />
        </a>
      </div> */}
          </Col>
          <Col lg={4}>
            <Resources downloads={[ {name: 'MCQ 01 2020 AL.pdf', link: 'https://storage.googleapis.com/revision-live.appspot.com/downloads/viduna-live/MCQ%2001%202020%20AL.pdf'} ]} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Discussion;