import React, { Component } from "react";

import { Button, Spinner, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { signInWithCustomToken } from "../../firebase/Firebase";

import { UserContext } from "../../providers/UserProvider";
import NavbarNotProtected from "../../components/NavbarNotProtected";
import Footer from "../../views/footer/Footer";
import ReactPasswordStrength from "react-password-strength";

import axios from "../../utils/axios";
import Logo from "../../components/Logo.Component";

class Login extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      phoneNumber: "+94",
      showPhoneNumberEnter: true,
      showPasswordEnter: false,
      showOTPVerification: false,
      errorMessage: "",
      verify: null,
      confirmCode: "",
      validPasswordStrength: false,
      newPassword: "",
      newPasswordConfirm: "",
      password: "",
    };
  }

  async onPressLogin() {
    const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

    if (
      this.state.phoneNumber === "+94" ||
      !phoneRegex.test(this.state.phoneNumber.trim())
    ) {
      this.setState({
        errorMessage: "Invalid Phone Number",
      });
    } else {
      this.setState({
        errorMessage: "",
      });
    }

    this.setState({
      loading: true,
    });

    const { phoneNumber } = this.state;
    var hasPassword = await this.doesUserHavePassword(phoneNumber.trim());

    if (hasPassword) {
      this.setState({
        showPhoneNumberEnter: false,
        showPasswordEnter: true,
      });
    } else {
      // send OTP
      var response = await this.sendStudentOTP(phoneNumber.trim());
      if (response && response.data.success) {
        this.setState({
          showPhoneNumberEnter: false,
          showOTPVerification: true,
        });
      } else {
        this.setState({
          errorMessage: response.data.message,
        });
      }
    }

    // const recaptcha = RecaptchaVerifier();

    // var login = await signInWithPhoneNumber(phoneNumber, recaptcha)
    //   .then((verify) => {
    //     this.setState({
    //       loading: false,
    //       verify: verify,
    //     });
    //     console.log(this.state.verify);
    //   })
    //   .catch((error) => {
    //     this.setState({
    //       errorMessage: error.code,
    //     });
    //     window.location.reload();
    //   });

    this.setState({
      loading: false,
    });

    // if (login) {
    //   this.setState({
    //     loading: false,
    //     phoneNumber: "+94",
    //   });
    // }
  }

  async doesUserHavePassword(phoneNumber) {
    var url = "/doesStudentHavePassword";
    var response = await axios.post(url, { phoneNumber: phoneNumber });
    if (response && response.data.success) {
      return response.data.data.hasPassword;
    }
    return false;
  }

  async sendStudentOTP(phoneNumber) {
    var url = "/sendOTP";
    var response = await axios.post(url, { phoneNumber: phoneNumber });
    return response;
  }

  async updatePassword(phoneNumber, code, password) {
    var url = "/updatePassword";
    var response = await axios.post(url, {
      phoneNumber: phoneNumber,
      confirmCode: code,
      password: password,
    });
    return response;
  }

  async login(phoneNumber, password) {
    var url = "/login";
    var response = await axios.post(url, {
      phoneNumber: phoneNumber,
      password: password,
    });
    return response;
  }

  async onUpdatePassword() {
    this.setState({
      loading: true,
    });

    let phoneNumber = this.state.phoneNumber.trim();
    let confirmCode = this.state.confirmCode.trim();
    // let validPasswordStrength = this.state.validPasswordStrength;
    let password = this.state.newPassword.trim();
    let passwordConfirm = this.state.newPasswordConfirm.trim();

    if (!confirmCode || confirmCode.length !== 6) {
      this.setState({
        errorMessage: "Confirm code must be at least 6 characters long!",
        loading: false,
      });
      return;
    }

    if (!password) {
      this.setState({
        errorMessage: "Password must be given!",
        loading: false,
      });
      return;
    }

    // if( !validPasswordStrength ){
    //   this.setState({
    //     errorMessage: 'Password must be stronger!',
    //     loading: false,
    //   })
    //   return;
    // }

    if (!passwordConfirm) {
      this.setState({
        errorMessage: "Password Confirm must be given!",
        loading: false,
      });
      return;
    }

    if (password !== passwordConfirm) {
      this.setState({
        errorMessage: "Password and Password Confirm must match!",
        loading: false,
      });
      return;
    }

    try {
      var passwordUpdatedResponse = await this.updatePassword(
        phoneNumber,
        confirmCode,
        password
      );

      if (passwordUpdatedResponse && passwordUpdatedResponse.data.success) {
        // login and redirect
        var token = passwordUpdatedResponse.data.data.token;
        if (token) {
          try {
            var authCred = await signInWithCustomToken(token);
            window.location.reload();
          } catch (error) {
            var errorMessage = error.message;
            this.setState({
              errorMessage: errorMessage,
              loading: false,
            });
          }
        } else {
          this.setState({
            errorMessage: "Error occurred! Please try again!",
            loading: false,
          });
        }
      } else {
        var message = passwordUpdatedResponse.data.message;
        this.setState({
          errorMessage: message,
          loading: false,
        });
      }
    } catch (err) {
      var message = "Error occurred!";
      this.setState({
        errorMessage: message,
        loading: false,
      });
    }

    // let verify = this.state.verify;
    // this.setState({
    //   verify: verify
    //     .confirm(confirmCode)
    //     .catch((error) => {
    //       this.setState({
    //         loading: false,
    //         errorMessage: error.code,
    //       });
    //     })
    //     .then(() => {}),
    // });
  }

  async onEnterPassword() {
    this.setState({
      loading: true,
    });

    let phoneNumber = this.state.phoneNumber.trim();
    let password = this.state.password.trim();

    if (!phoneNumber) {
      this.setState({
        errorMessage: "Phone Number must be given!",
        loading: false,
      });
      return;
    }

    if (!password) {
      this.setState({
        errorMessage: "Password must be given!",
        loading: false,
      });
      return;
    }

    try {
      var loginResponse = await this.login(phoneNumber, password);

      if (loginResponse && loginResponse.data.success) {
        // login and redirect
        var token = loginResponse.data.data.token;
        if (token) {
          try {
            var authCred = await signInWithCustomToken(token);
            window.location.reload();
          } catch (error) {
            var errorMessage = error.message;
            this.setState({
              errorMessage: errorMessage,
              loading: false,
            });
          }
        } else {
          this.setState({
            errorMessage: "Error occurred! Please try again!",
            loading: false,
          });
        }
      } else {
        var message = loginResponse.data.message;
        this.setState({
          errorMessage: message,
          loading: false,
        });
      }
    } catch (err) {
      var message = "Error occurred!";
      this.setState({
        errorMessage: message,
        loading: false,
      });
    }
  }

  showError() {
    if (this.state.errorMessage !== "") {
      return true;
    }
  }

  render() {
    const {
      showPhoneNumberEnter,
      showOTPVerification,
      showPasswordEnter,
    } = this.state;
    return (
      <div>
        <NavbarNotProtected fixed fluid />
        <div className="limiter">
          <div className="container-login100">
            {showPhoneNumberEnter && (
              <div className="wrap-login100 p-t-50 p-b-90">
                <form
                  className="login100-form validate-form flex-sb flex-w"
                  id="login-form"
                  onSubmit={(event) => {
                    event.preventDefault();
                    this.onPressLogin();
                  }}
                >
                  <div className="logo-wrapper">
                    <Logo className="logo" />
                  </div>
                  <label className="label-input">
                    Phone Number (Ex: +947XXXXXXXX)
                  </label>

                  <div className="wrap-input100 validate-input mb-3">
                    <input
                      className="input100"
                      type="phone"
                      name="phone"
                      placeholder="Phone Number"
                      value={this.state.phoneNumber}
                      onChange={(evt) => {
                        this.setState({
                          phoneNumber: evt.target.value,
                        });
                      }}
                    />
                    <span className="focus-input100"></span>
                  </div>

                  <div className="d-flex justify-content-between align-items-center w-100 pt-3 pb-4">
                    <div className="contact100-form-checkbox">
                      <input
                        className="input-checkbox100"
                        id="ckb1"
                        type="checkbox"
                        name="remember-me"
                      />
                      <label className="label-checkbox100" htmlFor="ckb1">
                        Remember me
                      </label>
                    </div>
                    <div>
                      <Link to="/reset-password" className="txt1">
                        Forgot Password?
                      </Link>
                    </div>
                  </div>

                  <div className="container-login100-form-btn m-t-17">
                    <Button
                      type="submit"
                      id="recaptcha-container"
                      className="login100-form-btn"
                      disabled={this.state.loading ? true : false}
                    >
                      {this.state.loading && <Spinner animation="border" />}
                      {!this.state.loading && "Login"}
                    </Button>
                  </div>
                  <div style={{ marginTop: 30, textAlign: "center" }}>
                    <p>
                      ඔබට ගැටලු ඇත්නම් ඒ සඳහා
                      <Link to="/faq">
                        {" "}
                        <u>මෙතන ක්ලික් කරන්න.</u>{" "}
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            )}

            {showOTPVerification && (
              <div className="wrap-login100 p-t-50 p-b-90">
                <form
                  className="login100-form validate-form flex-sb flex-w"
                  id="login-form"
                  onSubmit={(event) => {
                    event.preventDefault();
                    this.onUpdatePassword();
                  }}
                >
                  {/* <div className="logo-wrapper">
                    <img
                      className="logo"
                      src={require("../../assets/logo.png")}
                      alt="logo-dark"
                    />
                  </div> */}

                  <label className="label-input">Confirm Code</label>
                  <div className="wrap-input100 validate-input mb-3">
                    <input
                      className="input100"
                      type="text"
                      name="confirm"
                      placeholder="Enter OTP Code"
                      onChange={(evt) => {
                        this.setState({
                          confirmCode: evt.target.value,
                        });
                      }}
                    />
                    <span className="focus-input100"></span>
                  </div>

                  <label className="label-input">New Password</label>
                  <div className="wrap-input100 validate-input mb-3">
                    <ReactPasswordStrength
                      className="input100 strength-input"
                      // style={{ display: 'none' }}
                      minLength={5}
                      minScore={2}
                      scoreWords={[
                        "weak",
                        "okay",
                        "good",
                        "strong",
                        "stronger",
                      ]}
                      changeCallback={(evt) => {
                        this.setState({
                          validPasswordStrength: evt.isValid,
                          newPassword: evt.password,
                        });
                      }}
                      inputProps={{
                        name: "newPassword",
                        autoComplete: "off",
                        placeholder: "Enter New Password",
                        className: "input100",
                      }}
                    />
                    <span className="focus-input100"></span>
                  </div>

                  <label className="label-input">New Password Confirm</label>
                  <div className="wrap-input100 validate-input mb-3">
                    <input
                      className="input100"
                      type="password"
                      name="newPasswordConfirm"
                      placeholder="Confirm Password"
                      onChange={(evt) => {
                        this.setState({
                          newPasswordConfirm: evt.target.value,
                        });
                      }}
                    />
                    <span className="focus-input100"></span>
                  </div>

                  <div className="container-login100-form-btn m-t-17">
                    <Button
                      type="submit"
                      className="login100-form-btn"
                      disabled={this.state.loading ? true : false}
                    >
                      {this.state.loading && <Spinner animation="border" />}
                      {!this.state.loading && "Submit"}
                    </Button>
                    <div className="w-100">
                      <Link
                        onClick={() => {
                          this.setState({
                            errorMessage: "",
                          });
                        }}
                        className="cancel-otp"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            )}

            {showPasswordEnter && (
              <div className="wrap-login100 p-t-50 p-b-90">
                <form
                  className="login100-form validate-form flex-sb flex-w"
                  id="login-form"
                  onSubmit={(event) => {
                    event.preventDefault();
                    this.onEnterPassword();
                  }}
                >
                  {/* <div className="logo-wrapper">
                    <img
                      className="logo"
                      src={require("../../assets/logo.png")}
                      alt="logo-dark"
                    />
                  </div> */}

                  <label className="label-input">Password</label>
                  <div className="wrap-input100 validate-input mb-3">
                    <input
                      className="input100"
                      type="password"
                      name="password"
                      placeholder="Password"
                      onChange={(evt) => {
                        this.setState({
                          password: evt.target.value,
                        });
                      }}
                    />
                    <span className="focus-input100"></span>
                  </div>

                  <div className="d-flex justify-content-between align-items-center w-100 pt-3 pb-4">
                    <div>
                      <Link to="/reset-password" className="txt1">
                        Forgot Password?
                      </Link>
                    </div>
                  </div>

                  <div className="container-login100-form-btn m-t-17">
                    <Button
                      type="submit"
                      className="login100-form-btn"
                      disabled={this.state.loading ? true : false}
                    >
                      {this.state.loading && <Spinner animation="border" />}
                      {!this.state.loading && "Submit"}
                    </Button>
                    <div className="w-100">
                      <Link
                        onClick={() => {
                          this.setState({
                            errorMessage: "",
                          });
                        }}
                        className="cancel-otp"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            )}

            <HandleModel
              show={this.showError()}
              message={this.state.errorMessage}
              onCancel={() => {
                this.setState({
                  errorMessage: "",
                });
              }}
            />
          </div>
        </div>
        <Footer fixed light />
      </div>
    );
  }
}

const ErrorMessage = ({ message }) => (
  <div className="error-messasge">{message}</div>
);

const HandleModel = ({ onCancel, message, onHide, show }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Login;
