import React from "react";
import NavbarNotProtected from "../../components/NavbarNotProtected";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "styled-components";
import { Colors } from "../../theme/theme";
import Footer from "../footer/Footer";
import PageHeader from "../../components/PageHeader.Component";

const config = require("../../config.json");

const ExpansionPanel = withStyles({
  root: {
    width: "100%",
    boxShadow: "none",
    "&$expanded": {
      borderBottom: `2px solid ${config.theme.colors.primary}`,
      boxShadow: "0 1px 12px 0 rgba(0,0,0,.11)",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  details: {
    paddingRight: 30,
    paddingLeft: 30,
    paddingBottom: 30,
  },
}));

const FAQ = (props) => {
  return (
    <div className="bg-light">
      <NavbarNotProtected props={props} />
      <div className="container pb-5">
        <div className="row">
          <div className="col-md-9">
            <PageHeader
              title="ප්‍රශ්ණ හා පිළිතුරු"
              subtitle="අප ඔබට උපකාර විය හැක්කේ කෙසේද?"
            />
            <QuestionSectionRegistration />
            <QuestionSectionTakingClasses />
            <QuestionSectionDownloadingApp />
            <QuestionSectionPayments />
            <QuestionSectionTechnicalQuestions />
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
      <Footer light />
    </div>
  );
};

const QuestionSectionRegistration = () => {
  const classes = useStyles();
  return (
    <div>
      <div className="section-box mb-5">
        <h3 style={{ fontWeight: "bold", fontSize: 25 }} className="mb-4">
          ලියාපදිංචිය පිළිබඳව
        </h3>
        <div className={classes.root}>
          <ExpansionPanelBox
            title="ලියාපදිංචි වීම සඳහා අවශ්‍ය කුමක්ද?"
            description="භාවිතයේ පවතින ජංගම දුරකතනයක්"
          />
          <ExpansionPanelBox
            title="ලියාපදිංචි සිදු කරගන්නේ කෙසේද?"
            description={
              "පළමුව <a target='_blank' href='" +
              config.base_url +
              "/login'><u>මෙම පිටුවට ගොස්</u></a> දුරකථන අංකය ඇතුලත් කර Login බටන් එක ක්ලික් කරන්න, එවිට ලබාදුන් ජංගම දුරකතනයට පැමිණෙන අංක 6කින්  යුත් අංකය ඇතුලත් කරන්න."
            }
          />
          <ExpansionPanelBox
            title="ලියාපදිංචි  ගාස්තුවක් අය කරනවාද ?"
            description="නැත, කිසිම මුදලක් ලියාපදිංචිය සඳහා අය නොකෙරේ."
          />
          <ExpansionPanelBox
            title="ලියාපදීංචියේදී ඇතිවන ගැටලු - CAPTCHA විසඳාගැනීම"
            image={require("../../assets/captcha.png")}
            description="රූපයේ සඳහන් ආකාරයේ ගැටලුවක් ඔබට පැමිණියේ නම් එයින් දැක්වෙන්නේ Google මෘදුකාංගයක් විසින් ඔබ සැබවින්ම මනුෂ්‍යයෙක්ද බව තහවුරු කරගැනීමට අවශ්‍ය බව දන්වා ඇතිබවයි. 
            එහි එක් එක් වේලාවට එක් එක් ගැටලු පැමිණෙන අතර ඔබ කළයුත්තේ අසා ඇති ගැටලුවට නිවැරදි පිළිතුරු ලබාදී 'Verify' button එක ක්ලික් කිරීමයි."
          />
        </div>
      </div>
    </div>
  );
};

const QuestionSectionPayments = () => {
  const classes = useStyles();
  return (
    <div>
      <div className="section-box mb-5">
        <h3 style={{ fontWeight: "bold", fontSize: 25 }} className="mb-4">
          මුදල් ගෙවීම් පිළිබඳව
        </h3>
        <div className={classes.root}>
          <ExpansionPanelBox
            title="මුදල් ගෙවිය හැකි මාධ්‍යයන් මොනවද?"
            image={require("../../assets/payment_methods.png")}
          />
          <ExpansionPanelBox
            title="මුදල් ගෙවීමේදී වැරදීමක් සිදු වුවහොත් ,"
            description="එය එසැනින් නැවත Account  එක වෙත refund වීමක් සිදු කෙරේ."
          />
          <ExpansionPanelBox
            title="මුදල් ගෙවීමේ ගැටළුවක් ඇති වුවහොත් "
            description="අප හා සම්බන්ද වී  එම ගැටලුව නිරාකරණය කර ගත හැක. එය සඳහා transaction id  එක ලබාගෙන අප සම්බන්ද කරගන්න.
            transaction id  එක payment එක අවසානයේ පෙන්වනු ඇත. එසේ නැත්නම් කාඩ්පතෙහි අදාල බැංකුවට කතා කර ලබාගන්න."
          />
        </div>
      </div>
    </div>
  );
};
const QuestionSectionTakingClasses = () => {
  const classes = useStyles();
  return (
    <div>
      <div className="section-box mb-5">
        <h3 style={{ fontWeight: "bold", fontSize: 25 }} className="mb-4">
          පාඩම් සඳහා සහභාගී වන ආකාරය
        </h3>
        <div className={classes.root}>
          <ExpansionPanelBox
            title="සෑම පාඩමක් සඳහාම අය කරනවද ?"
            description="  සම්පුර්ණයෙන්ම නොමිලේ සහ ගෙවීම් සිදුකර යන දෙවර්ගයේම පාඩම් මේ සඳහා එකතු කෙරේ. එය පාඩම් තෝරන අවස්ථාවේදී පෙන්නුම් කෙරෙනු ලැබේ."
          />
          <ExpansionPanelBox
            title="
            සජීවී පාඩම් නරඹන්නේ කෙසේද?"
            description="පෙර සජීවී පාඩම් Book කර යුතුය. එවිට පාඩම් පටන් ගැනීමට ලක් වන විට ඔබ දැනුවත් කරණු ඇත."
          />
        </div>
      </div>
    </div>
  );
};

const QuestionSectionDownloadingApp = () => {
  const classes = useStyles();
  return (
    <div>
      <div className="section-box mb-5">
        <h3 style={{ fontWeight: "bold", fontSize: 25 }} className="mb-4">
          MCQ ප්‍රශ්නපත්‍රවලට මුහුණදීම ගැන
        </h3>
        <div className={classes.root}>
          <ExpansionPanelBox
            title="SmartPhone App එක Download කරගන්නේ කෙසේද?"
            description="ඔබගේ Android දුරකථනයෙන් Google PlayStore හි Revision LIVE ලෙස සෙවීමෙන් හෝ <a href='https://play.google.com/store/apps/details?id=com.explorelogy.revisionlive'><u>මෙතැන ක්ලික් කිරීමෙන්</u></a> Download කරගැනීමට හැකියාව ඇත.
            ඔබ දැනට භාවිතාකරන්නේ Apple දුරකථනයක් නම් දැනට මෙම යෙදුම ඔබ හට Download කරගැනීමට හැකියාවක් නොමැත. එය ඉතා ඉක්මනින් Apple දුරකථනවලටද ලබාදීමට කටයුතු කරමින් පවතී."
          />
          <ExpansionPanelBox
            title="App එකේ Register වන්නේ කෙසේද?"
            description="යෙදුම Install කරගැනීමෙන් පසුව ඔබ මෙම අඩවියට ලියාපදිංචි වීම සඳහා යොදාගත් දුරකථන අංකයම යොදා ඇතුළුවන්න."
          />
        </div>
      </div>
    </div>
  );
};

const QuestionSectionTechnicalQuestions = () => {
  const classes = useStyles();
  return (
    <div>
      <div className="section-box mb-5">
        <h3 style={{ fontWeight: "bold", fontSize: 25 }} className="mb-4">
          වෙනත් තාක්ෂණික ගැටලු සඳහා
        </h3>
        <div className={classes.root}>
          <ExpansionPanelBox
            title="මෙහි සඳහන්කොට නැති තාක්ෂණික ගැටලු විසඳාගැනීම සඳහා"
            description={
              "ඔබට Register වීමේදී, Login වීමේදී වැනි අවස්ථාවලදී තාක්ෂණික ගැටලුවලට මුහුණදීමට සිදුවූයේ නම් " +
              supportNumbersDisplay() +
              " දුරකථන අංකවලට හෝ " +
              supportEmailDisplay() +
              " යන ඊමේල් ලිපිනයට දැනුම් දෙන්න."
            }
          />
        </div>
      </div>
    </div>
  );
};

const supportNumbersDisplay = () => {
  const numbers = config.faq.support_numbers;
  let arr = [];
  for (var i = 0; i < numbers.length; i++) {
    var supportNumber = numbers[i];
    var str =
      "<a href='" +
      supportNumber["href"] +
      "'><u>" +
      supportNumber["display"] +
      "</u></a>";
    arr.push(str);
  }

  let fullStr = arr.join(" / ");
  return fullStr;
};

const supportEmailDisplay = () => {
  const supportEmail = config.faq.support_email;
  let str =
    "<a href='mailto:" + supportEmail + "'><u>" + supportEmail + "</u></a>";
  return str;
};

const ExpansionPanelTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.textLight};
`;

const ExpansionPanelBox = ({ title, description, image }) => {
  const classes = useStyles();
  return (
    <ExpansionPanel rounded="true" className={classes.root}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>
          <ExpansionPanelTitle>{title}</ExpansionPanelTitle>
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        {image ? <img src={image} alt="Expansion" /> : null}

        <div style={{ color: Colors.textLight }}>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default FAQ;
