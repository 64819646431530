import React from "react";
import NavbarNotProtected from "../../components/NavbarNotProtected";
import PageHeader from "../../components/PageHeader.Component";
import Footer from "../footer/Footer";

const Contact = (props) => {
  return (
    <div className="contact-us">
      <NavbarNotProtected props={props} />
      <div className="container pb-5">
        <div className="row">
          <div className="col-md-9">
            <PageHeader
              title="සම්බන්ධ වන්න"
              subtitle="ඔබගේ සියලුම විමසීම් සදහා නියෝජිතයකු සමග සම්බන්ධ වන්න."
            />
          </div>
        </div>
      </div>
      <Footer light />
    </div>
  );
};

export default Contact;
