import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "../providers/UserProvider";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const user = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          if( user && user.hasOwnProperty('type') && user.type == 'TEACHER' ){
            return <Redirect to={{ pathname: "/landing" }} />;  
          }

          if (user && user.hasOwnProperty('signupCompleted') && user.signupCompleted === true ) {
            if( rest.path == '/' ){
              return <Redirect to={{ pathname: "/landing" }} />; 
            }
            return <Component {...props} user={user} />;
             
          } else if( user && user.hasOwnProperty('firstName') && user.firstName ) {
            return <Redirect to={{ pathname: "/education" }} />;
          } else {
            return <Redirect to={{ pathname: "/landing" }} />;            
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          );
        }
      }}
    />
  );
};
