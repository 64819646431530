import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// theme
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./theme/theme";

//styles
import "./styles/styles";

import { GlobalStyles } from "./theme/global";

//login screen
import Login from "./auth/login/Login";
import Reset from "./auth/reset/Reset";

//signup screen
import Register from "./auth/Register/Register";
import Education from "./auth/Register/Education";

//main screen
import Home from "./views/home/Home";
import Profile from "./views/profile/Profile";
import UserProvider from "./providers/UserProvider";
import { AuthRoute } from "./routes/auth.routes";
import { ProtectedRoute } from "./routes/protected.routes";
import FAQ from "./views/faq/FAQ";
import Contact from "./views/contact/Contact";
import TermsAndConditions from "./views/terms_conditions/TermsAndCondition";
import PrivacyPolocy from "./views/privacy_policy/PrivacyPolicy";
import Landing from "./views/landing/Landing";

import Discussion from "./views/discussion/Discussion";
import Discussion2021 from "./views/discussion/Discussion2021";
import Discussion20202 from "./views/discussion/Discussion20202";
import Discussion20212 from "./views/discussion/Discussion20212";
import Discussion2022 from "./views/discussion/Discussion2022";

import {auth, findUserDocument} from './firebase/Firebase';

function App() {
  const [theme, setTheme] = useState("light");
  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  const [userData,setUserData] = useState(null);
  const [authWasListened,setAuthWasListened] = useState(false);

  useEffect(()=>{
    auth.onAuthStateChanged(
      async (authUser) => {

        if(authUser) {
          var userData = await findUserDocument(authUser);
          setUserData(userData);
          setAuthWasListened(true);
        } else {
          setUserData(null);
          setAuthWasListened(true);
        }

      }
    );
  },[]);

  return (
    authWasListened ?
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <GlobalStyles />
      <UserProvider user={userData}>
        <Router>
          <Switch>
            <Route path="/faq" component={FAQ} />
            <Route path="/contact" component={Contact} />
            <Route
              path="/terms-and-conditions"
              component={TermsAndConditions}
              />
            <Route path="/privacy-policy" component={PrivacyPolocy} />
          </Switch>

          <Switch>
            <AuthRoute exact path="/login" component={Login} />
            <AuthRoute exact path="/reset-password" component={Reset} />
            <AuthRoute exact path="/landing" component={Landing} partial/>
            <AuthRoute exact path="/register" component={Register} partial/>
            <AuthRoute exact path="/education" component={Education} partial/>
            <AuthRoute exact path="/profile" component={Profile} />
          </Switch>
          <Switch>
            <ProtectedRoute exact path="/" component={Home} />
            <ProtectedRoute exact path="/2020AL" component={Discussion} />
            <ProtectedRoute exact path="/2021AL" component={Discussion2021} />
            <ProtectedRoute exact path="/2021AL-2" component={Discussion20212} />
            <ProtectedRoute exact path="/2020AL-2" component={Discussion20202} />
            <ProtectedRoute exact path="/2022ALTheory" component={Discussion2022} />
          </Switch>
        </Router>
      </UserProvider>
    </ThemeProvider>
    :
    <div></div>
  );
}

export default App;
