import React, { Component } from "react";

import { Button, Spinner, Modal } from "react-bootstrap";

import { Link } from "react-router-dom";

import {
  updateStudentEducationDetails
} from "../../firebase/Firebase";

const config = require("../../config.json");

class Education extends Component {
  constructor(props) {
    super(props);

    // medium
    var selectedMedium = config.education.mediums && config.education.mediums.length > 0 
      ? config.education.mediums[0].name : '';
    
    // grade
    var selectedGrade = config.education.grades && config.education.grades.length > 0 
      ? config.education.grades[0] : null;
    var gradeUid = selectedGrade ? selectedGrade.value : '';
    var gradeName = selectedGrade ? selectedGrade.name : '';
    
    // stream
    var selectedStream = config.education.should_stream_selectable && config.education.streams && config.education.streams.length > 0 
      ? config.education.streams[0] : null;
    var streamUid = selectedStream ? selectedStream.value : '';
    var streamName = selectedStream ? selectedStream.name : '';

    this.state = {
      loading: false,
      medium: selectedMedium,
      grade: gradeUid,
      gradeName: gradeName,
      stream: streamUid,
      streamName: streamName,
      errorMessage: "",
    };
  }

  async onSignup(event){
    event.stopPropagation();

    let medium = this.state.medium.trim();
    let grade = this.state.grade.trim();
    let gradeName = this.state.gradeName.trim();
    let stream = this.state.stream.trim();
    let streamName = this.state.streamName.trim();

    if( !medium ){
      let errorMessage = "Medium must be given!";      
      this.setError(errorMessage)
      return;
    }else if( !grade || !gradeName ){
      let errorMessage = "Grade must be given!";      
      this.setError(errorMessage)
      return;

    }else if( config.education.should_stream_selectable && ( !stream || !streamName )){
      let errorMessage = "Stream must be given!";      
      this.setError(errorMessage)
      return;

    }

    let success = await updateStudentEducationDetails(medium, grade, gradeName, stream, streamName, true);
    if( success ){
      this.props.history.push("/landing");
    }else{
      this.setError('Error occurred!');
    }
  }

  setError(message){
    this.setState({
      errorMessage: message
    })
  }

  clearError(){
    this.setState({
      errorMessage: ''
    })
  }

  showError() {
    return this.state.errorMessage.trim() !== "";
  }

  render() {
    // const { firstName, lastName, email, school, next } = this.state;
    return (
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100 p-t-50 p-b-90">
            <form
              className="login100-form validate-form flex-sb flex-w"
              id="login-form"
            >
              <div className="logo-wrapper">
                <h1>EDUCATION</h1>
              </div>

              <div>
                <div>
                  <label>Select Your Medium</label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    onChange={(evt) => {
                      this.setState({
                        medium: evt.target.value,
                      });
                    }}
                  >
                    {
                      config.education.mediums
                        .sort((a, b) => a.index - b.index)
                        .map((medium) =>
                        <option key={medium.name} value={medium.name}>{medium.name}</option>
                      )
                    }
                  </select>
                </div>
                <div className="mt-3">
                  <label>Select Your Grade</label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    onChange={(evt) => {
                      
                      let gradeName = '';
                      let selectedOptions = evt.target.selectedOptions;
                      if( selectedOptions && selectedOptions.length > 0 ){
                        gradeName = selectedOptions[0].innerText;
                      }

                      this.setState({
                        grade: evt.target.value,
                        gradeName: gradeName
                      });
                    }}
                  >
                    {
                      config.education.grades
                        .sort((a, b) => a.index - b.index)
                        .map((grade) =>
                        <option key={grade.value} value={grade.value}>{grade.name}</option>
                      )
                    }
                  </select>
                </div>
                {
                  config.education.should_stream_selectable && 
                  <div className="mt-3">
                    <label>Select Your Stream</label>
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      onChange={(evt) => {

                        let streamName = '';
                        let selectedOptions = evt.target.selectedOptions;
                        if( selectedOptions && selectedOptions.length > 0 ){
                          streamName = selectedOptions[0].innerText;
                        }

                        this.setState({
                          stream: evt.target.value,
                          streamName: streamName
                        });
                      }}
                    >
                      {
                        config.education.streams
                          .sort((a, b) => a.index - b.index)
                          .map((stream) =>
                          <option key={stream.value} value={stream.value}>{stream.name}</option>
                        )
                      }
                    </select>
                  </div>
                }
                <div className="container-login100-form-btn mt-5">
                  <Button
                    onClick={this.onSignup.bind(this)}
                    className="login100-form-btn"
                    disabled={this.state.loading ? true : false}
                  >
                    {this.state.loading && <Spinner animation="border" />}
                    {!this.state.loading && "FINISH"}
                  </Button>
                </div>
              </div>
            </form>
          </div>

          <HandleModel
              show={this.showError()}
              message={this.state.errorMessage}
              onCancel={this.clearError.bind(this)}
            />
        </div>
      </div>
    );
  }
}

const HandleModel = ({ onCancel, message, show }) => {
  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ErrorMessage = ({ message }) => (
  <div className="error-messasge">{message}</div>
);

// const HandleModel = ({ onCancel, message, onHide, show }) => {
//   return (
//     <Modal show={show} onHide={onHide}>
//       <Modal.Body>{message}</Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={onCancel}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

export default Education;
