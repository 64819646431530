import React, { Component } from "react";

import NavbarNotPretected from './NavbarNotProtected';
import NavbarComponent from './Navbar.Component';

class AuthAwareNavbar extends Component{

    render(){
        let user = this.props.user;
        if( user ){
            return (
                <NavbarComponent user={user} />
            );
        }
        return (
            <NavbarNotPretected />
        );
    }

}

export default AuthAwareNavbar;