import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyBOVc9k_SyKYIFVcZqui5vzHp62HD1LY_A",
  authDomain: "revision-live.firebaseapp.com",
  databaseURL: "https://revision-live.firebaseio.com",
  projectId: "revision-live",
  storageBucket: "revision-live.appspot.com",
  messagingSenderId: "514557211443",
  appId: "1:514557211443:web:ca1a24c784a643371c4446",
  measurementId: "G-BC0NVT8Q8V",
};
firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

const appConfig = require("../config.json");

//sign in phone
export const signInWithPhoneNumber = async (phoneNumber, appVerify) => {
  return auth.signInWithPhoneNumber(phoneNumber, appVerify);
};

// custom auth
export const signInWithCustomToken = async(token) =>{
  return auth.signInWithCustomToken(token);
}

export const RecaptchaVerifier = () => {
  return new firebase.auth.RecaptchaVerifier("recaptcha-container", {
    size: "invisible",
    // other options
  });
};

// sign in
export const signInWithEmailAndPassword = async (email, password) => {
  return auth.signInWithEmailAndPassword(email, password);
};

export const reauthenticateWithPasssword = async (password) => {
  var user = auth.currentUser;
  var credential = firebase.auth.EmailAuthProvider.credential(
    user.email,
    password
  );
  return user.reauthenticateWithCredential(credential);
};

export const signOut = async () => {
  return auth.signOut();
};

export const findUserDocument = async (user, additionalData) => {
  if (!user) return null;

  var uid = user.uid;
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();

    return {
      uid,
      ...userDocument.data(),
    };
  } catch (error) {
    console.error("Error fetching user", error);
  }
};

export const updatePassword = async (newPassword, newPasswordConfirm) => {
  if (!newPassword || !newPasswordConfirm) {
    return;
  }
  if (newPassword !== newPasswordConfirm) {
    console.log("incorrect confirm");
    throw new Error("incorrect confirm");
  }

  var user = auth.currentUser;
  return user.updatePassword(newPassword);
};

/**
 * SIGNUP 
 */

var onUserDetailsUpdatedFunction = null;
export const onUserDetailsUpdated = (functionObj) =>{
  onUserDetailsUpdatedFunction = functionObj;
};

export const updateStudentBasicDetails = async(firstName, lastName, email, schoolName, district) => {
  var user = auth.currentUser;

  if( user ){
    var uid = user.uid;
    try {
      const userDocumentRef = firestore.doc(`users/${uid}`);

      var data = {
        type: 'STUDENT',
        signupCompleted: false,
        phoneNumber: user.phoneNumber,
        firstName: firstName,
        lastName: lastName,
        schoolName: schoolName,
        district: district
      };
      
      if( email && email.length > 0 ){
        data.email = email;
      }

      await userDocumentRef.set(data, { merge: true });
      if( onUserDetailsUpdatedFunction != null ){
        onUserDetailsUpdatedFunction(user);
      }
      await updateFavoriteTeacher(uid, appConfig.teacher_uid);

      return true;

    } catch (error) {
      console.error("Error updating details", error);
    }
  }
  return false;
}

export const updateFavoriteTeacher = async(studentUid, teacherUid) =>{

  try{

    const favoriteTeachersCollectionRef = firestore.collection('student_favorite_teachers');

    // check if already has
    let existingFavoriteSnapshot = await favoriteTeachersCollectionRef
      .where('student_uid', '==', studentUid).where('teacher_uid', '==', teacherUid).get();

    if( existingFavoriteSnapshot.empty ){
      let favoriteTeacherRef = favoriteTeachersCollectionRef.doc();
      let createdAt = new Date();
      await favoriteTeacherRef.set({
        student_uid: studentUid,
        teacher_uid: teacherUid,
        created_at: createdAt
      })

      return true;
    }

  }catch(error){
    console.error('Error writing favorite teacher!');
  }

  return false;
}

export const updateStudentEducationDetails = async(medium, gradeUid, gradeName, streamUid, streamName, signupCompleted) => {
  var user = auth.currentUser;
  debugger;
  if( user ){
    var uid = user.uid;
    try {
      const userDocumentRef = firestore.doc(`users/${uid}`);

      var data = {
        medium: medium,
        gradeUid: gradeUid,
        gradeName: gradeName,
        streamUid: streamUid,
        streamName: streamName
      };

      if( signupCompleted ){
        data['signupCompleted'] = true;
      }

      await userDocumentRef.set(data, { merge: true });
      if( onUserDetailsUpdatedFunction != null ){
        onUserDetailsUpdatedFunction(user);
      }

      return true;

    } catch (error) {
      console.error("Error updating details", error);
    }
  }
  return false;
}