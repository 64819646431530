import React from "react";

import * as Icon from "react-feather";

const Resources = (props) => {
  return (
    <div className="py-3">
      <h4>Resources</h4>
      <div>
        {
          props.downloads && Array.isArray(props.downloads) && props.downloads.length > 0 && 
          props.downloads.map((download, index) =>
            <div className="bg-light p-2 download-file" key={index}>
              <span style={{ fontSize: 14 }}>
                <Icon.FileText
                  size={20}
                  style={{ color: "#555", marginRight: 5, marginLeft: 5 }}
                />{" "}
                { download.name }
              </span>
              <a className="btn btn-primary btn-sm" href={download.link} target="_blank">
                <Icon.DownloadCloud size={14} />
              </a>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default Resources;
