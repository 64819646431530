import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

export default class SelectSubject extends Component {
  render() {
    return (
      <div>
        <h5 className="pt-4 pl-4">Select Your Subject</h5>
        <div className="container pt-4">
          <div className="row justify-content-center">
            <SubjectItem title="Mathematics" path="/select-teacher" />
            <SubjectItem title="Chemistry" path="/select-teacher" />
            <SubjectItem title="Physics" path="/select-teacher" />
          </div>
        </div>
      </div>
    );
  }
}

const SubjectItem = ({ title, path }) => {
  return (
    <div className="col-md-4 p-3 text-center">
      <Link to={path}>
        <Card body className="shadow-sm border-primary">
          <h5 className="m-0 text-primary">{title}</h5>
        </Card>
      </Link>
    </div>
  );
};
