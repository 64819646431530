import React from "react";

const Logo = ({ width, className }) => (
  <div className="p-1">
    <img
      className={className}
      src={require("../assets/logo_darshana.png")}
      width={width}
      alt="logo brand"
    />
  </div>
);

export default Logo;
