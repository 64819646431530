import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Colors } from "../../theme/theme";
import { Nav } from "react-bootstrap";

const logo = require("../../assets/logo.png");
const revisionLogo = require("../../assets/svg/logo.svg");

const Footer = ({ fixed, light }) => {
  const SLink = styled.span`
    color: ${light ? "#000" : "#fff"};
    transition: all 0.3s ease;
    font-size: 13px;
    &:hover {
      color: ${Colors.primary};
    }
  `;
  return (
    <footer className={fixed ? "footer-fixed-bottom" : ""}>
      <div
        className={
          light
            ? "footer-light lower-footer container-fluid"
            : " lower-footer container-fluid"
        }
      >
        <div className="d-flex align-items-center">
          <span>Powered by</span>
          {/* <img width="80" src={logo} alt="Logo Footer" /> */}
          <a target="_blank" href="https://live.revision.lk/">
            <img src={revisionLogo} width="100" alt="Logo Footer" />
          </a>
        </div>
        {/* <Nav className="ml-auto">
          <li className="nav-item">
            <NavLink className="nav-link" to="/privacy-policy">
              <SLink>රහස්‍යතා ප්‍රතිපත්තිය</SLink>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/terms-and-conditions">
              <SLink>නියමයන් හා කොන්දේසි</SLink>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/contact">
              <SLink>සම්බන්ධ වන්න</SLink>
            </NavLink>
          </li>
        </Nav> */}
      </div>
    </footer>
  );
};

export default Footer;
