import React from "react";
import NavbarNotProtected from "../../components/NavbarNotProtected";
import Footer from "../footer/Footer";
import PageHeader from "../../components/PageHeader.Component";

const PrivacyPolocy = (props) => {
  return (
    <div>
      <NavbarNotProtected props={props} />
      <div className="container pb-5">
        <div className="row">
          <div className="col-md-9">
            <PageHeader
              title="රහස්‍යතා ප්‍රතිපත්තිය"
              subtitle="අවසන් යාවත්කාලීන කිරීම 2019, නොවැම්බර් 1 වන දින"
            />
          </div>
        </div>
      </div>
      <Footer light />
    </div>
  );
};

export default PrivacyPolocy;
