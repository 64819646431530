import React from "react";

const PageHeader = ({ title, subtitle }) => {
  return (
    <header className="py-5 my-4">
      <div>
        <h1 style={{ fontWeight: "bold" }}>{title}</h1>
        <div>{subtitle}</div>
      </div>
    </header>
  );
};

export default PageHeader;
