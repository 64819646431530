import React from "react";
import NavbarNotProtected from "../../components/NavbarNotProtected";
import PageHeader from "../../components/PageHeader.Component";
import Footer from "../footer/Footer";

const TermsAndConditions = (props) => {
  return (
    <div>
      <NavbarNotProtected props={props} />
      <div className="container pb-5">
        <div className="row">
          <div className="col-md-9">
            <PageHeader
              title="නියමයන් හා කොන්දේසි"
              subtitle="අවසන් යාවත්කාලීන කිරීම 2019, නොවැම්බර් මස 01 වන දා"
            />
          </div>
        </div>
      </div>
      <Footer light />
    </div>
  );
};

export default TermsAndConditions;
