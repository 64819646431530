import React, { useContext, Component } from "react";
import Layout from "../app.layout";

//vimeo
import Vimeo from "@u-wave/react-vimeo";
import YouTube from 'react-youtube';
import { Col, Row } from "react-bootstrap";

import Resources from "../../components/Resources.Component";
import { Switch, Route } from "react-router-dom";

//screens
import SelectSubject from "../select_subject/SelectSubject";
import SelectTeacher from "../select_teacher/SelectTeacher";

const videos = [
  { id: 0, title: '1', vimeoId: 'eY46_fMAoqg'},
];

class Video extends Component{
  constructor(props){
    super(props);
  }

  onVideoClick(index){
    this.props.onClick(index);
  }

  render(){
    return (
      <div style={{
        width: 100,
        height: 100,
        padding: 5,
        display: 'inline-block',
        border: '1px solid black',
        margin: 3,
        textAlign: "center",
        lineHeight: '90px'
      }}
        onClick={() => this.onVideoClick(this.props.id)}
      >
        {this.props.name}
      </div>
    );
  }
}

const Discussion20202 = (props) => {
  return (
    <Layout user={props.user}>
      <Switch>
        <Route path="/" component={DiscussionBody} />
        <Route path="/select-subject" component={SelectSubject} />
        <Route path="/select-teacher" component={SelectTeacher} />
      </Switch>
    </Layout>
  );
};

class DiscussionBody extends Component {
  constructor(props){
    super(props);
    this.state = {
      currentVideoIndex: 0,
      width: 800,
      height: 450
    };
  }

  componentDidMount(){
    var fullWidth = window.innerWidth;

    var width = this.state.width;
    var height = this.state.height;

    if( fullWidth <= 768 ){
      width = fullWidth - 30;
      height = width / 16 * 9

    }else if( fullWidth <= 990 ){
      width = fullWidth - 60;
      height = width / 16 * 9

    }else{
      width = (fullWidth / 3 * 2) - 50;
      height = width / 16 * 9
    }

    this.setState({
      width: width,
      height: height
    })
  }

  getVimeoId(){
    var index = this.state.currentVideoIndex;
    var filtered = videos.filter(i => i.id == index);

    if( filtered && filtered.length > 0 ){
      return filtered[0].vimeoId; 
    }else{
      return videos[0].vimeoId;
    }    
  }

  getOpts(){
    var opts = {
      width: this.state.width,
      height: this.state.height
    };
    return opts;
  }

  onVideoClick(videoIndex){
    this.setState({
      currentVideoIndex: videoIndex
    })
  }

  render() {
    return (
      <div>
        <Row>
          <Col lg={8}>
            {/* <Vimeo color="007bf" video={this.getVimeoId()} responsive /> */}
            <YouTube videoId={this.getVimeoId()} opts={this.getOpts()} />
          </Col>
          <Col className="pt-3 pt-lg-0 pl-lg-0" lg={4}>
            <div className="question-answers-view p-3">
              <div>
                <h6>Videos</h6>
              </div>
              <div className="question-list-view" id="style-1">
                <div className="overflow-view px-2 py-1">
                  { videos && Array.isArray(videos) && videos.length > 0 &&
                      videos.map((video, index) => (
                        <Video
                          key={index}
                          id={video.id}
                          name={ video.title }
                          vimeoId={video.vimeoId}
                          onClick={this.onVideoClick.bind(this)}
                        />
                      ))
                  }
                </div>
              </div>
            </div>
            {/* <VideoSection videos={videos} onClick={ () => this.onVideoClick } /> */}
          </Col>
        </Row>
        <Row style={{ marginBottom: 100 }}>
          <Col lg={8}>
            {/* <div className="py-3 pl-1">
        <h4 style={{ fontWeight: "bold" }}>Sample Video Title</h4>
        <p style={{ fontSize: 14 }}>
          Et in aliqua ut est aliquip laborum fugiat. Sit pariatur amet sint
          qui ut non ipsum sit ex veniam qui ad. Et in aliqua ut est aliquip
          laborum fugiat. Sit pariatur amet sint qui ut non ipsum sit ex
          veniam qui ad.{" "}
        </p>
        <a style={{ fontSize: 14 }} href="/#">
          Show more <Icon.ChevronDown size={15} />
        </a>
      </div> */}
          </Col>
          <Col lg={4}>
            <Resources downloads={[ {name: '2020 MCQ Paper 2.pdf', link: 'https://storage.googleapis.com/revision-live.appspot.com/downloads/viduna-live/2020%20MCQ%20Paper%202.pdf'} ]} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Discussion20202;