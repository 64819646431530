import React, { Component } from "react";
import { withRouter } from "react-router";
import AuthAwareNavbar from "../../components/AuthAwareNavbar.component";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";

import Vimeo from "@u-wave/react-vimeo";
import VisibilitySensor from "react-visibility-sensor";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { testomonials } from "../../assets/testomonials/testomonials";

class Landing extends Component {
  visibilityChanged(isVisible) {
    if (isVisible) {
      let user = this.props.user;
      let history = this.props.history;

      let path = null;
      if (!user || !user.hasOwnProperty("signupCompleted")) {
        path = "/register";
      } else if (
        user &&
        user.hasOwnProperty("signupCompleted") &&
        user.signupCompleted === false
      ) {
        path = "/education";
      }

      if (path != null) {
        setTimeout(function () {
          history.push(path);
        }, 1500);
      }
    }
  }

  render() {
    var user = this.props.user;
    return (
      <div>
        <AuthAwareNavbar user={this.props.user} />
        {/* <div
          style={{ background: "#0D64B3" }}
          className="container-fluid  py-5"
        >
          <div className="container-sm-fluid container-md py-5">
            <Vimeo color="007bf" video="420285248" responsive />
          </div>
        </div> */}
        {/* <DiscussionCountDownTitle opensAt={new Date(2020, 4, 25, 18, 0)} /> */}
        <div className="container steps-introduction">

          <section className="py-5 my-4">
            <div>
              <h4 style={{ fontWeight: "bold", marginBottom: 20 }}>
                පියවර 1 - හඳුන්වාදීම
              </h4>
              <p>
                live.darshanah.lk යනු මෙම වෙබ් අඩවියයි. මෙහිදී කරනුයේ මාර්ගගත (Online) ක්‍රමයෙන් සාමාන්‍ය පෙළ ගණිතය විෂයට අදාල පාඩම් ඉගැන්වීම (Theory), පුනරීක්ෂණ (Revision) සහ බහුවරණ ප්‍රශ්න පත්‍ර (MCQ) විවරණය කිරීමයි.
              </p>
              <p>Revision Live යනු ඔබ ඉගෙනුම් ක්‍රියාවලිය සඳහා මුහුණ දීමට Install කර ගත යුතු Smart Phone යෙදුමයි </p>
            </div>
          </section>
          <section className="py-5 my-4">
            <div>
              <h4 style={{ fontWeight: "bold", marginBottom: 20 }}>
                පියවර 2 - Register වීම
              </h4>
              <p>
                පළමුවෙන්ම මෙම අඩවියේ Register වීම සඳහා මෙම පිටුවේ පහළට Scroll කරමින් යන්න. එවිට ඔබව Registration Application එකක් වෙත ගෙනයනු ඇති. එහිදී ඔබගේ නිවැරදි තොරතුරු ඇතුළු කිරීමට වගබලාගන්න. එම Application එක සම්පූර්ණ කළ පසු ඔබව මෙම පිටුවට නැවතත් ඉබේම ගෙන එනු ඇති. එවිට ඔබගේ Registration එක සාර්ථක වී තිබෙනු ඇත.

              </p>
            </div>
          </section>
          <section className="py-5 my-4">
            <div>
              <h4 style={{ fontWeight: "bold", marginBottom: 20 }}>
                පියවර 3 - Smart Phone යෙදුම බාගත කිරීම
              </h4>
              <p>
                පාඩම් වලට සහභාගි වීම සඳහා ඔබගේ Smart ජංගම දුරකථනයේ මාදිළිය අනුව (Android/Apple) අදාළ යෙදුම පහතින් Download කර Install කරගන්න. ඉන්පසුව එම යෙදුම විවෘත කොට මෙම පිටුවේ Register වීමට යෙදූ දුරකථන අංකය යොදා එයට ඇතුළු වන්න. එය ඉබේම සක්‍රීය වනු ඇති.
              </p>
            </div>
            <div className="col-md-5 mx-auto">
              <div
                className="row justify-content-center align-items-center"
                style={{ textAlign: "center" }}
              >
                <div className="col-md-6">
                  <img
                    width="190"
                    className="img-responsive"
                    src={require("../../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg")}
                    alt=""
                  />
                </div>
                <div className="col-md-6">
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.explorelogy.revisionlive"
                  >
                    <img
                      width="240"
                      className="img-responsive"
                      src={require("../../assets/google-play-badge.png")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="col-md-12">
                  <div className="col-md-6">( Coming Soon )</div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="container steps-introduction">
          {/* <section className="py-5 my-4">
            <div>
              <h4 style={{ fontWeight: "bold", marginBottom: 20 }}>
                පියවර 4 - ප්‍රශ්නපත්‍රවලට මුහුණදීම හා විවරණ නැරඹීම
              </h4>
              <p>
                මෙම පිටුවේ ඇති පහත වගුවෙන් ඔබට ඔබගේ වසරට අදාළ MCQ ප්‍රශ්නපත්‍ර
                තිබෙන දිනයන් හා එහි විවරණ තිබෙන දිනයන් සොයාගත හැක. පළමුව
                ප්‍රශ්නපත්‍රය තිබෙන දිනය සොයාගෙන Revision LIVE එක තුළින් විභාගය
                ආරම්භ වන වේලාවට පෙර හෝ පෙරදිනයකදී එය Book කරන්න. එසේ කිරීමෙන්
                පසු ඔබට විභාගය පැවැත්වෙන වේලාවට මතක් කිරීමක් සිදුවේ. ඉන්පසු
                විභාගය පැවැත්වෙන වේලාවට Revision LIVE එකෙන් විභාගයට මුහුණ දී
                ප්‍රතිඵල ලබාගත හැක. තෙවනුව වීඩියෝ එකේ ඇති කාලසටහනට අනුව ඔබ ඒ
                මුහුණ දුන් ප්‍රශ්නපත්‍රයේ විවරණය මෙම පිටුවට පැමිණීමෙන් නැරඹිය
                හැක.
              </p>
            </div>
          </section> */}
          <section className="py-5 my-4">
            <div>
              <h4 style={{ fontWeight: "bold", marginBottom: 20 }}>
                පියවර 4 - වෙනත් ගැටලු
              </h4>
              <p>
                ඔබට මේ පිළිබඳ වෙනත් ගැටලු ඇත්නම් ඒ සඳහා
                <Link to="/faq">
                  {" "}
                  <u>මෙතන ක්ලික් කරන්න.</u>{" "}
                </Link>
              </p>
            </div>
          </section>
        </div>

        <div
          className="container-fluid"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <VisibilitySensor
            onChange={this.visibilityChanged.bind(this)}
            partialVisibility={true}
            offset={10}
            scrollCheck={true}
          >
            <img width="100%" src={require("../../assets/mock/cover.png")} />
          </VisibilitySensor>
        </div>
        <Footer light />
      </div>
    );
  }
}

class DiscussionCountDown extends Component {
  interval = null;

  constructor(props) {
    super(props);

    if (props.opensAt && props.link) {
      this.state = {
        opensAt: props.opensAt,
        link: props.link,
        display: "",
      };
    }
  }

  componentDidMount() {
    if (!this.isOpen()) {
      var app = this;
      this.interval = setInterval(() => {
        var msDifference = app.getOpenTimeDifference();
        var timeString = "";

        if (msDifference < 0) {
          var date = new Date(0);
          date.setMilliseconds(Math.abs(msDifference));
          timeString = date.toISOString().substr(11, 8);

          var noOfHours = Math.floor(Math.abs(msDifference) / (60 * 60 * 1000));
          if (noOfHours > 24) {
            timeString = noOfHours + timeString.substr(2);
          }
        }

        app.setState({
          display: timeString,
        });
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getOpenTimeDifference() {
    var now = new Date();
    var opensAt = this.state.opensAt.getTime();
    var nowTime = now.getTime();

    return nowTime - opensAt;
  }

  isOpen() {
    return this.getOpenTimeDifference() >= 0;
  }

  render() {
    if (this.isOpen()) {
      return (
        <Link to={this.state.link} className="btn primary-button">
          View
        </Link>
      );
    } else {
      return <div>{this.state.display}</div>;
    }
  }
}

class DiscussionCountDownTitle extends Component {
  interval = null;

  constructor(props) {
    super(props);

    if (props.opensAt) {
      this.state = {
        opensAt: props.opensAt,
        display: "",
      };
    }
  }

  componentDidMount() {
    if (!this.isOpen()) {
      var app = this;
      this.interval = setInterval(() => {
        var msDifference = app.getOpenTimeDifference();
        var timeString = "";

        if (msDifference < 0) {
          var date = new Date(0);
          date.setMilliseconds(Math.abs(msDifference));
          timeString = date.toISOString().substr(11, 8);
        }

        app.setState({
          display: timeString,
        });
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getOpenTimeDifference() {
    var now = new Date();
    var opensAt = this.state.opensAt.getTime();
    var nowTime = now.getTime();

    return nowTime - opensAt;
  }

  isOpen() {
    return this.getOpenTimeDifference() >= 0;
  }

  getDisplayText() {
    if (this.isOpen()) {
      return "2021 A/L MCQ Paper 1 විවරණය දැන් ඔබට නැරඹිය හැකිය. පහතින් ඇති වගුවේ දක්වා ඇති තැනින් 'View' ඔබන්න";
    } else {
      return "2021 A/L MCQ Paper 1 විවරණය ආරම්භ වීමට " + this.state.display;
    }
  }

  render() {
    return (
      <div>
        <h3
          style={{
            fontWeight: "bold",
            marginBottom: 40,
            padding: 30,
            textAlign: "center",
            backgroundColor: "yellow",
          }}
        >
          {this.getDisplayText()}
        </h3>
      </div>
    );
  }
}

const TestomonialSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="container py-5 text-center">
      <Slider autoplaySpeed={5000} autoplay {...settings}>
        {testomonials.map((comment, index) => (
          <div>
            <div className="testomonial-image">
              <img
                width="200"
                className="mx-auto p-3"
                src={comment.image}
                alt={comment.name}
              />
            </div>
            <div className="testomonial-quote col-md-10 mx-auto pt-4">
              <p style={{ fontSize: 14, color: "#fff" }}>"{comment.comment}"</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default withRouter(Landing);
