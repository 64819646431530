import React from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "./Logo.Component";

import { Dropdown, Nav } from "react-bootstrap";

import * as Icon from "react-feather";

const NavbarNotProtected = ({ fixed, fluid, props }) => {
  return (
    <div className={"container-fluid position-relative"}>
      <nav
        className={`${
          fluid ? "container-fluid" : "container "
        }  navbar navbar-light ${fixed ? " fixed-top-navbar" : " "}`}
      >
        <Link className="navbar-brand" to="/landing">
          <Logo width="80" />
        </Link>
        <Nav className="ml-auto">
          <li className="nav-item active">
            <Link
              className="nav-link"
              style={{ fontSize: 14, fontWeight: "500" }}
              to="/faq"
            >
              ප්‍රශ්ණ හා පිළිතුරු
            </Link>
          </li>
          <li className="nav-item">
            <Dropdown className="nav-link">
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                Account
              </Dropdown.Toggle>
              <Dropdown.Menu className="theme-navbar-dropdown" alignRight>
                {/* <Dropdown.Item>Profile</Dropdown.Item>
                <Dropdown.Item>Billing</Dropdown.Item>
                <Dropdown.Item>About</Dropdown.Item> */}
                <Dropdown.Item as={NavLink} to="/login">
                  Login
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </Nav>
      </nav>
    </div>
  );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="/#"
    className=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <Icon.ChevronDown size={20} />
  </a>
));

export default NavbarNotProtected;
