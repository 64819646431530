import React, { Component, createContext } from "react";

import { auth, findUserDocument, onUserDetailsUpdated } from "../firebase/Firebase";

export const UserContext = createContext({ user: null });
class UserProvider extends Component {

  constructor(props){
    super(props);
    this.state = {
      user: this.props.user
    }
  }

  componentDidMount = () => {
    // auth.onAuthStateChanged(async (userAuth) => {
    //   await this.findUserDataAndUpdate(userAuth);
    // });

    onUserDetailsUpdated(async (userAuth) =>{
      await this.findUserDataAndUpdate(userAuth);
    })
  };

  async findUserDataAndUpdate(userAuth){
    const user = await findUserDocument(userAuth);
    this.setState({ user: user });
  }

  render() {
    return (
      <UserContext.Provider value={this.state.user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
export default UserProvider;
