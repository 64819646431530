import React, { useContext, Component } from "react";
import Layout from "../app.layout";

//vimeo
import Vimeo from "@u-wave/react-vimeo";
import { Col, Row } from "react-bootstrap";

import QASection from "../../components/QA.Component";

import Resources from "../../components/Resources.Component";
import { Switch, Route } from "react-router-dom";

//screens
import SelectSubject from "../select_subject/SelectSubject";
import SelectTeacher from "../select_teacher/SelectTeacher";

const Home = ({ themeHandle }) => {
  return (
    <Layout>
      <Switch>
        <Route path="/" component={Dashboard} />
        <Route path="/select-subject" component={SelectSubject} />
        <Route path="/select-teacher" component={SelectTeacher} />
      </Switch>
    </Layout>
  );
};

class Dashboard extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col lg={8}>
            <Vimeo color="007bf" video="418223023" responsive autoplay />
          </Col>
          <Col className="pt-3 pt-lg-0 pl-lg-0" lg={4}>
            <QASection />
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
            {/* <div className="py-3 pl-1">
        <h4 style={{ fontWeight: "bold" }}>Sample Video Title</h4>
        <p style={{ fontSize: 14 }}>
          Et in aliqua ut est aliquip laborum fugiat. Sit pariatur amet sint
          qui ut non ipsum sit ex veniam qui ad. Et in aliqua ut est aliquip
          laborum fugiat. Sit pariatur amet sint qui ut non ipsum sit ex
          veniam qui ad.{" "}
        </p>
        <a style={{ fontSize: 14 }} href="/#">
          Show more <Icon.ChevronDown size={15} />
        </a>
      </div> */}
          </Col>
          <Col lg={4}>
            <Resources />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Home;
