import React from "react";
import { Navbar, Dropdown, Nav } from "react-bootstrap";

import Logo from "./Logo.Component";

import * as Icon from "react-feather";
import { signOut } from "../firebase/Firebase";
import { NavLink } from "react-router-dom";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="/#"
    className=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <Icon.ChevronDown size={20} />
  </a>
));

const NavbarComponent = (props) => {
  let userName = null;
  if( props.user && props.user.firstName ){
    userName = props.user.firstName;
  }

  return (
    <Navbar className="theme-navbar">
      <Navbar.Brand href="/landing">
        <Logo width="100" />
      </Navbar.Brand>
      {/* <Nav className="mr-auto">
        <Nav.Link href="#home">Home</Nav.Link>
        <Nav.Link href="#features">Features</Nav.Link>
        <Nav.Link href="#pricing">Pricing</Nav.Link>
      </Nav> */}
      <Nav className="ml-auto">
        <Nav.Link>
          <NavLink className="nav-link" to="/faq">
            ප්‍රශ්ණ හා පිළිතුරු
          </NavLink>
        </Nav.Link>
        <Nav.Link>
          <Dropdown className="nav-link">
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              { (userName) ? userName : 'Account' }
            </Dropdown.Toggle>
            <Dropdown.Menu className="theme-navbar-dropdown" alignRight>
              <Dropdown.Item>
                <NavLink to="/profile">
                  Profile
                </NavLink>
              </Dropdown.Item>
              {/* <Dropdown.Item>Profile</Dropdown.Item>
              <Dropdown.Item>Billing</Dropdown.Item>
              <Dropdown.Item>About</Dropdown.Item> */}
              <Dropdown.Item onClick={() =>{
                signOut();
                window.location.reload();
                }}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default NavbarComponent;
