import React, { Component } from "react";

import { Button, Spinner, Modal } from "react-bootstrap";
import {
  updateStudentBasicDetails
} from "../../firebase/Firebase";

import "bootstrap-select/dist/css/bootstrap-select.min.css";
import "bootstrap-select/dist/js/bootstrap-select";
import { Link } from "react-router-dom";
import {
  signInWithPhoneNumber,
  RecaptchaVerifier,
  auth,
} from "../../firebase/Firebase";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      firstName: "",
      lastName: "",
      email: "",
      school: "",
      district: "",
      errorMessage: "",
    };
  }

  async onSignup(event){
    event.stopPropagation();

    let firstName = this.state.firstName.trim();
    let lastName = this.state.lastName.trim();
    let email = this.state.email.trim();
    let school = this.state.school.trim();
    let district = this.state.district.trim();

    if( !firstName ){
      let errorMessage = "First Name must be given!";      
      this.setError(errorMessage)
      return;
    }else if( !lastName ){
      let errorMessage = "Last Name must be given!";      
      this.setError(errorMessage)
      return;

    }else if( !school ){
      let errorMessage = "School must be given!";      
      this.setError(errorMessage)
      return;

    }else if( !district ){
      let errorMessage = "District must be given!";      
      this.setError(errorMessage)
      return;

    }

    let success = await updateStudentBasicDetails(firstName, lastName, email, school, district);
    if( success ){
      this.props.history.push("/education");
    }else{
      this.setError('Error occurred!');
    }
  }

  setError(message){
    this.setState({
      errorMessage: message
    })
  }

  clearError(){
    this.setState({
      errorMessage: ''
    })
  }

  showError() {
    return this.state.errorMessage.trim() !== "";
  }

  render() {
    const { firstName, lastName, email, school } = this.state;
    return (
      <div className="limiter">
        <div className="container-login100">

          <div style={{
            position:"fixed",
            top:30,
            right:40,
            width: 40,
            height: 40
          }}>
            <Link to={`/landing`}>
              <img
                className="img-responsive"
                src={require("../../assets/svg/close.svg")}
              />
            </Link>
          </div>

          <div className="wrap-login100 p-t-50 p-b-90">
            <form
              className="login100-form validate-form flex-sb flex-w"
              id="login-form"
            >
              <div className="logo-wrapper">
                <h1>REGISTER</h1>
              </div>

              <div>
                <RegiserInput
                  value={firstName}
                  placeholder="First Name"
                  type="text"
                  onChange={(evt) => {
                    this.setState({
                      firstName: evt.target.value,
                    });
                  }}
                />
                <RegiserInput
                  value={lastName}
                  placeholder="Last Name"
                  type="text"
                  onChange={(evt) => {
                    this.setState({
                      lastName: evt.target.value,
                    });
                  }}
                />
                <RegiserInput
                  value={email}
                  placeholder="Email"
                  type="email"
                  onChange={(evt) => {
                    this.setState({
                      email: evt.target.value.trim(),
                    });
                  }}
                />
                <RegiserInput
                  value={school}
                  placeholder="School"
                  type="text"
                  onChange={(evt) => {
                    this.setState({
                      school: evt.target.value,
                    });
                  }}
                />

                <label>Select Your District</label>
                <div className="wrap-input100 validate-input mb-3">                  
                  <select
                    className="input100 form-control"
                    id="district"
                    name="district"
                    placeholder="District"
                    onChange={(evt) => {
                      this.setState({
                        district: evt.target.value,
                      });
                    }}
                    required={true}
                  >
                    <option>--</option>
                    <option value='ampara'>Ampara</option>
                    <option value='anuradhapura'>Anuradhapura</option>
                    <option value='badulla'>Badulla</option>
                    <option value='batticaloa'>Batticaloa</option>
                    <option value='colombo'>Colombo</option>
                    <option value='galle'>Galle</option>
                    <option value='gampaha'>Gampaha</option>
                    <option value='hambantota'>Hambantota</option>
                    <option value='jaffna'>Jaffna</option>
                    <option value='kalutara'>Kalutara</option>
                    <option value='kandy'>Kandy</option>
                    <option value='kegalle'>Kegalle</option>
                    <option value='kilinochchi'>Kilinochchi</option>
                    <option value='kurunegala'>Kurunegala</option>
                    <option value='mannar'>Mannar</option>
                    <option value='matale'>Matale</option>
                    <option value='matara'>Matara</option>
                    <option value='monaragala'>Monaragala</option>
                    <option value='mullaitivu'>Mullaitivu</option>
                    <option value='nuwara_eliya'>Nuwara Eliya</option>
                    <option value='polonnaruwa'>Polonnaruwa</option>
                    <option value='puttalam'>Puttalam</option>
                    <option value='ratnapura'>Ratnapura</option>
                    <option value='trincomalee'>Trincomalee</option>
                    <option value='vavuniya'>Vavuniya</option>
                  </select>
                  <span className="focus-input100"></span>
                </div>
                <div className="container-login100-form-btn mt-5">
                  <Button
                    type="button"
                    id="recaptcha-container"
                    onClick={this.onSignup.bind(this)}
                    className="login100-form-btn"
                    disabled={ this.state.loading }
                  >
                    {this.state.loading && <Spinner animation="border" />}
                    {!this.state.loading && "Next"}
                  </Button>
                </div>
              </div>
            </form>
          </div>

          <HandleModel
              show={this.showError()}
              message={this.state.errorMessage}
              onCancel={this.clearError.bind(this)}
            />
        </div>
      </div>
    );
  }
}

const HandleModel = ({ onCancel, message, show }) => {
  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const RegiserInput = ({ value, onChange, placeholder, type }) => {
  return (
    <div className="wrap-input100 validate-input mb-3">
      <input
        className="input100"
        type={type}
        name={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        //   onChange={(evt) => {
        //     this.setState({
        //       phoneNumber: evt.target.value,
        //     });
        //   }}
      />
      <span className="focus-input100"></span>
    </div>
  );
};

const ErrorMessage = ({ message }) => (
  <div className="error-messasge">{message}</div>
);

// const HandleModel = ({ onCancel, message, onHide, show }) => {
//   return (
//     <Modal show={show} onHide={onHide}>
//       <Modal.Body>{message}</Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={onCancel}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

export default Register;
