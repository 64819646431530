import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body {
    // color: ${({ theme }) => theme.text};
    margin: 0;
    padding: 0;
    // font-family: 'Poppins' sans-serif;
    // transition: all 0.25s linear;
  }`;
