import React, { useState } from "react";
import { Form, Button, Accordion, useAccordionToggle } from "react-bootstrap";

import * as Icon from "react-feather";

const question_data = [
  {
    name: "John Doe",
    question: "What is your name?",
    profile_pic: require("../assets/student_profile.png"),
    answered: true,
    liked: 3,
  },
  {
    name: "Micheal Jackson",
    question: "Who is the father of the computer?",
    profile_pic: require("../assets/student_profile-2.png"),
    answered: false,
  },
  {
    name: "John H Watson",
    question: "What is the most popular programming language?",
    profile_pic: require("../assets/student_profile-3.png"),
    answered: false,
  },
];

//Q&A section
const QASection = () => {
  return (
    <div className="question-answers-view p-3">
      <div>
        <h6>Questions</h6>
      </div>
      <div className="question-list-view" id="style-1">
        <div className="overflow-view px-2 py-1">
          {/* {question_data.map((question, index) => (
            <QuestionAndAnswers
              key={index}
              name={question.name}
              image={question.profile_pic}
              question={question.question}
              answered={question.answered}
              liked={question.liked}
            />
          ))} */}
        </div>
      </div>
      <AddQuestion />
    </div>
  );
};

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    console.log("totally custom!")
  );

  return (
    <div
      type="button"
      style={{ backgroundColor: "transparent" }}
      onClick={decoratedOnClick}
    >
      {children}
    </div>
  );
}

const QuestionAndAnswers = ({ question, name, image, answered, liked }) => {
  const [like, setLike] = useState(0);
  return (
    <Accordion>
      <div className="question-main w-100 p-3">
        <div className="question-wrapper d-flex">
          <CustomToggle eventKey="0">
            <div>
              <div className="question-item d-flex">
                <div className="question-profile">
                  <div className="profile-wrapper">
                    <img src={image} alt="question profile" />
                  </div>
                </div>
                <div>
                  <div className="question-profile-content d-flex align-items-baseline">
                    <p>{name}</p>
                    <span className="question-time">a few seconds ago</span>
                    {answered ? (
                      <span className="answered-label">Answered</span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="question-value">{question}</div>
                </div>
              </div>
            </div>
          </CustomToggle>
          <div>
            {liked == null ? (
              <span
                onClick={() => setLike(1)}
                className={`like-button  ${
                  like === 0
                    ? "btn-outline-primary "
                    : "btn-primary d-flex align-items-center "
                } btn btn-sm  px-2`}
              >
                {like === 0 ? (
                  ""
                ) : (
                  <span style={{ marginRight: 10 }}>{like}</span>
                )}
                <Icon.ThumbsUp size={13} />
              </span>
            ) : (
              <span
                onClick={() => setLike(1)}
                className="like-button  btn btn-sm btn-primary d-flex align-items-center px-2 rounded-lg"
              >
                <span style={{ marginRight: 10 }}>{liked + like}</span>
                <Icon.ThumbsUp size={13} />
              </span>
            )}
          </div>
        </div>
        {answered ? (
          <Accordion.Collapse eventKey="0">
            <div className="answers-view p-2 mt-3">
              <AnswerItem />
            </div>
          </Accordion.Collapse>
        ) : (
          ""
        )}
      </div>
    </Accordion>
  );
};

const AnswerItem = ({ answer, name, image }) => {
  return (
    <div className="answer-item d-flex">
      <div className="answer-profile">
        <div className="profile-wrapper">
          <img src={require("../assets/teacher.png")} alt="question profile" />
        </div>
      </div>
      <div>
        <div className="question-profile-content d-flex align-items-baseline">
          <p>Teacher</p>
          <span className="question-time">a few seconds ago</span>
        </div>

        <div className="question-value">This is the Sample Answer</div>
      </div>
    </div>
  );
};

const AddQuestion = () => {
  return (
    <Form.Group className="pt-3 mb-0 add-question">
      <div className="d-flex">
        <Form.Control
          className="rounded-0"
          type="text"
          style={{ fontSize: 14 }}
          placeholder="Type question here"
        />
        <Button
          disabled
          className="rounded-0 d-flex align-items-center"
          style={{ fontSize: 14 }}
        >
          Send <Icon.ArrowUp size={14} />
        </Button>
      </div>
      <Form.Text style={{ color: "#aaa", marginLeft: 5 }}>
        Ask the Teacher
      </Form.Text>
    </Form.Group>
  );
};

export default QASection;
