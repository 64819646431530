import React, { Component } from "react";

export default class SelectTeacher extends Component {
  render() {
    return (
      <div>
        <h5 className="pt-4 pl-4">Select Your Teacher</h5>
        <div className="container pt-4">
          <div className="row justify-content-center"></div>
        </div>
      </div>
    );
  }
}
