import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "../providers/UserProvider";

const redirect = (path) => {
  return (
    <Redirect
      to={{
        pathname: path,
      }}
    />
  );
}

export const AuthRoute = ({ component: Component, ...rest }) => {
  const user = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user || ( rest.partial && user && user.uid )) {

          if( user ){
            if( ( rest.path.startsWith('/register') || rest.path.startsWith('/education' ) ) 
                  && user.hasOwnProperty('signupCompleted') && user.signupCompleted === true ){

              return redirect('/landing');

            }else if( rest.path.startsWith('/register') && user.hasOwnProperty('signupCompleted') && user.signupCompleted === false ){
              return redirect('/education');

            }else if( rest.path.startsWith('/education') && !( user.hasOwnProperty('firstName') && user.firstName ) ){
              return redirect('/register');

            }
          }else if( !user && rest.path.startsWith('/landing') ){
            return redirect('/login');
          }

          return <Component {...props} user={user} />;
          
        } else {

          if( rest.path.startsWith('/profile') ){
            props['user'] = user;
            return <Component {...props} />;
          }else{
            return redirect('/landing');
          }

        }
      }}
    />
  );
};
