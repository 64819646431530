import React, { Component } from "react";

import { Button, Spinner, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  updateStudentEducationDetails
} from "../../firebase/Firebase";

import { UserContext } from "../../providers/UserProvider";
import Navbar from "../../components/Navbar.Component";
import Footer from "../../views/footer/Footer";

const config = require("../../config.json");

class Profile extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isInitialized: false,
      medium: "",
      grade: "",
      gradeName: "",
      stream: "",
      streamName: "",
      message: ""
    };
  }

  componentDidMount(){
    var user = this.props.user;
    if( user && !this.state.isInitialized ){
      var medium = user.medium;
      var grade = user.gradeUid;
      var gradeName = user.gradeName;
      var stream = user.streamUid;
      var streamName = user.streamName;

      this.setState({
        isInitialized: true,
        medium: medium,
        grade: grade,
        gradeName: gradeName,
        stream: stream,
        streamName: streamName
      });
    }
  }

  async onPressUpdate() {
    this.setState({
      loading: true
    });
    
    // get data
    var medium = this.state.medium;
    var grade = this.state.grade;
    var gradeName = this.state.gradeName;
    var stream = this.state.stream;
    var streamName = this.state.streamName;

    if( !medium ){
      this.setState({
        message: 'Medium must be given!',
        loading: false
      })
      return;
    }

    if( !grade ){
      this.setState({
        message: 'Grade must be given!',
        loading: false
      })
      return;
    }

    if( config.education.should_stream_selectable && !stream ){
      this.setState({
        message: 'Stream must be given!',
        loading: false
      })
      return;
    }

    let success = await updateStudentEducationDetails(medium, grade, gradeName, stream, streamName);
    if( success ){
      this.setState({
        message: 'Successfully updated details!',
        loading: false
      })
    }else{
      this.setState({
        message: 'Error occurred!',
        loading: false
      })
    }
  }

  showMessage() {
    if (this.state.message !== "") {
      return true;
    }
  }

  render() {
    return (
      <div>
        <Navbar fixed fluid user={this.props.user} />
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-profile100 p-t-50 p-b-90">
                <form
                  className="login100-form validate-form flex-sb flex-w"
                  id="login-form"
                  onSubmit={(event) => {
                    event.preventDefault();
                    this.onPressUpdate();
                  }}
                >
                  <div className="profile-photo-wrapper">
                    <img
                      className="profile-photo"
                      src={require("../../assets/user.png")}
                      alt="profile"
                    />
                  </div>

                  <label className="label-input">Select Your Medium</label>
                  <div className="wrap-input100 validate-input mb-3">                    
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={this.state.medium}
                      onChange={(evt) => {
                        this.setState({
                          medium: evt.target.value,
                        });
                      }}
                    >
                      <option value=""> -- </option>
                      {
                        config.education.mediums
                          .sort((a, b) => a.index - b.index)
                          .map((medium) =>
                          <option key={medium.name} value={medium.name}>{medium.name}</option>
                        )
                      }
                    </select>
                  </div>

                  <label className="label-input">Select Your Grade</label>
                  <div className="wrap-input100 validate-input mb-3">                    
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={this.state.grade}
                      onChange={(evt) => {
                        
                        let gradeName = '';
                        let selectedOptions = evt.target.selectedOptions;
                        if( selectedOptions && selectedOptions.length > 0 ){
                          gradeName = selectedOptions[0].innerText;
                        }

                        this.setState({
                          grade: evt.target.value,
                          gradeName: gradeName
                        });
                      }}
                    >
                      <option value=""> -- </option>
                      {
                        config.education.grades
                          .sort((a, b) => a.index - b.index)
                          .map((grade) =>
                          <option key={grade.value} value={grade.value}>{grade.name}</option>
                        )
                      }
                    </select>
                  </div>

                  {
                    config.education.should_stream_selectable && 
                    <div>
                      <label className="label-input">Select Your Stream</label>
                      <div className="wrap-input100 validate-input mb-3">                    
                        <select
                          className="form-control"
                          id="exampleFormControlSelect1"
                          value={this.state.stream}
                          onChange={(evt) => {

                            let streamName = '';
                            let selectedOptions = evt.target.selectedOptions;
                            if( selectedOptions && selectedOptions.length > 0 ){
                              streamName = selectedOptions[0].innerText;
                            }

                            this.setState({
                              stream: evt.target.value,
                              streamName: streamName
                            });
                          }}
                        >
                          <option value=""> -- </option>
                          {
                            config.education.streams
                              .sort((a, b) => a.index - b.index)
                              .map((stream) =>
                              <option key={stream.value} value={stream.value}>{stream.name}</option>
                            )
                          }
                        </select>
                      </div>
                    </div>
                  }

                  <div className="container-login100-form-btn m-t-17">
                    <Button
                      type="submit"
                      id="recaptcha-container"
                      className="login100-form-btn"
                      disabled={this.state.loading ? true : false}
                    >
                      {this.state.loading && <Spinner animation="border" />}
                      {!this.state.loading && "Submit"}
                    </Button>
                  </div>
                </form>
              </div>

            <HandleModel
              show={this.showMessage()}
              message={this.state.message}
              onCancel={() => {
                this.setState({
                  message: ''
                })
              }}
            />
          </div>
        </div>
        <Footer fixed light />
      </div>
    );
  }
}

const HandleModel = ({ onCancel, message, onHide, show }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Profile;
